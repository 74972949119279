import * as React from "react";
import { Link } from "gatsby";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import { withLayout, LayoutProps, menuItems } from "../components/Layout";
import {
  Button,
  Segment,
  Container,
  Grid,
  Header,
  Icon,
} from "semantic-ui-react";

const IndexPage = (props: LayoutProps) =>
  <div>
    {/* Master head */}
    <Segment vertical inverted textAlign="center" className="masthead">
      <HeaderMenu
        Link={Link} pathname={props.location.pathname} items={menuItems} inverted
      />
      <Container text>
        <Header inverted as="h1" size="large">Begin Your Journey as a Champion of Creation Through:</Header>
        <Header inverted as="h2" color = "blue" size="huge">Skymake</Header>
        <Header inverted as="h2">Data-enabled Decision Making - Full-Stack Development - Cloud Development</Header>
        <Link to="/blog/2019-04-04--welcome/"><Button inverted color="blue" primary size="huge">Take on Perspectives of Creation</Button></Link>
      </Container>
    </Segment>

    {/* About this starter */}
    <Segment vertical className="stripe">
      <Grid stackable verticalAlign="middle" className="container">
        <Grid.Row>
          <Grid.Column width="8">
            <Header>Data Science</Header>
            <p>
              Leverage the power of analyzing data to make effective decisions
                  when achieving your goals.
                </p>
            <Header>Full-Stack Development</Header>
            <p>
              In bringing your ideas to life, examine the facets of launching
                  mobile and web applications, and discover modern tools to
                  quickly forge new ideas.
                </p>
          </Grid.Column>
          <Grid.Column width="6" floated="right">
            {/* TODO replace with a pretty GIF */}
            <Header>Cloud Computing</Header>
            <p>
              Discover, learn, assimilate highly available cloud resources and
                  stand on the shoulders of giants with reduced costs to entry.
                </p>
            <Header>Operations</Header>
            <p>
              Discover how modern tools and technology enable the creation of
                  your ideas, easing product back-end and operations efforts.
                </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    {/* Key features */}
    <Segment vertical className="stripe alternate feature">
      <Grid columns="3" textAlign="center" divided relaxed stackable className="container">
        <Grid.Row>
          <Grid.Column>
            <Header icon>
              <Icon name="chart area"></Icon>
              Power of Data-science!
            </Header>
            <p>
              Use modern analytic techniques and data to refine your idea.
                  Witness the power of these techniques to maintain and
                  spread your idea-given-form post-launch. Strive with us to
                  wield this tool with precision and accuracy. Critically design
                  data experiments and carefully analyze public data,
                  or scrape your own from the land.
                </p>
          </Grid.Column>
          <Grid.Column>
            <Header icon>
              <Icon name="clock outline"></Icon>
              Agile Development!
            </Header>
            <p>
              Design and launch your idea swiftly from beginning to end, assuming
                  minimal knowledge of industry skills for any part of the stack.
                  Dig deeper only when you need to. Prioritize the crystallization
                  of your idea as you shape it from your thoughts and give it form.
                </p>
          </Grid.Column>
          <Grid.Column>
            <Header icon>
              <Icon name="cloud upload"></Icon>
              Cloud Powered!
            </Header>
            <p>
              Enter the modern landscape of app development as large corporations
                  have lowered the costs of entry, providing low-cost per-use and
                  as-you-grow fees for access to servers and computational power.
                  Navigate these tools with us carefully so that your idea is only
                  limited by how far you are willing to go.
                </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  </div>;

export default withLayout(IndexPage);
